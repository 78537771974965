<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    console.log("© https://woerk.dev");
  },
  mounted() {
    let _this = this;

    window.AFRAME.registerComponent("router", {
      schema: {
        path: { type: "string", default: "" }
      },
      multiple: true,
      events: {
        click: function() {
          let data = this.data;
          _this.$router
            .push({
              name: "Window",
              params: {
                window: data.path
              }
            })
            .catch(() => {});
        }
      }
    });
  }
};
</script>

<style>
</style>
