<template>
  <page-item
    :content="$route.params.window === 'about' ? about : page[0]"
    v-if="page"
  />
</template>

<script>
import PageItem from "../components/PageItem.vue";
import about from "../content/about.json";
import pages from "../content/pages.json";

export default {
  components: { PageItem },
  data() {
    return {
      about: about,
      page: undefined
    };
  },
  created() {
    this.page = pages.filter(page => page.route === this.$route.params.window);
  }
};
</script>