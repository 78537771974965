import Vue from "vue";
import Vuex from "vuex";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import "./assets/css/main.css";

Vue.use(Vuex);
Vue.use(VueMeta);

const store = new Vuex.Store({
  state: {
    settings: {
      muted: false,
    },
    intro: {
      visible: true,
    },
    scene: {
      loaded: false,
    },
  },
  mutations: {
    HIDE_INTRO(state) {
      state.intro.visible = false;
    },
    SCENE_STATUS(state, status) {
      if (status === "loaded") {
        setTimeout(() => {
          state.scene.loaded = true;
        }, 500);
      } else if (status === "loading") {
        state.scene.loaded = false;
      }
    },
    MUTE_AUDIO(state, status) {
      state.settings.muted = status;
    },
  },
});

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^a-/];

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
