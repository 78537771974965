<template>
  <div class="page window glow--white" v-if="content">
    <div class="page__wrapper">
      <div class="page__title">
        {{ `Deep Fake / ${content.title}`
        }}<span class="page__back pointer" @click="back">ⓧ</span>
      </div>
      <the-settings class="page__settings glow--white" />
      <div class="page__text" v-html="content.text"></div>
      <div class="page__video" v-if="content.video !== ''">
        <iframe
          :src="`https://player.vimeo.com/video/${content.video}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import TheSettings from "./TheSettings.vue";
export default {
  props: {
    content: Object
  },
  components: { TheSettings },
  data() {
    return {
      window: {
        width: 0
      }
    };
  },
  methods: {
    back() {
      this.$router.push({
        path: "/",
        name: "Home"
      });
    },
    getWindowWidth() {
      this.window.width = window.innerWidth;
    }
  },
  mounted() {
    this.getWindowWidth();
    window.addEventListener("resize", this.getWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>

<style scoped>
.page {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 10;
  overflow-y: auto;
}

.page__wrapper > * {
  margin-bottom: calc(var(--unit) * 3);
}

.page__wrapper > *:last-child {
  margin-bottom: 0;
}

.page__settings {
  position: absolute;
  top: calc(var(--unit) * 5);
  right: calc(var(--unit) * 5);
}

.page__video {
  padding: 56.25% 0 0 0;
  position: relative;
}

.page__video > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page__back {
  margin-left: var(--unit);
}
</style>