<template>
  <transition name="fade">
    <div class="intro window glow--white" v-if="$store.state.intro.visible">
      <div class="intro__wrapper">
        <div class="intro__title">
          Deep Fake
          <span class="intro__enter pointer" @click="hide">(Enter)</span>
        </div>
        <div class="intro__text" v-html="intro.text"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import intro from "../content/intro.json";

export default {
  data() {
    return {
      intro: intro
    };
  },
  metaInfo() {
    return {
      htmlAttrs: {
        class: ""
      }
    };
  },
  methods: {
    hide() {
      this.$store.commit("HIDE_INTRO");
    }
  }
};
</script>

<style scoped>
.intro {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 30;
  overflow-y: auto;
}

.intro__wrapper {
  opacity: 0;
  animation: fade var(--time-3) normal forwards;
  animation-delay: 1s;
}

.intro__title {
  margin-bottom: calc(var(--unit) * 3);
}

.intro__enter {
  margin-left: var(--unit);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>