<template>
  <div class="scene">
    <div class="scene__title glow--black">
      Deep Fake
      <span class="scene__about pointer" @click="go('about')">ⓘ</span>
    </div>
    <the-settings class="scene__settings" />
    <a-scene
      loading-screen="enabled: false"
      vr-mode-ui="enabled: false"
      fog="type: exponential; near: 100; far: 1000"
      light="defaultLightsEnabled: false"
    >
      <a-assets ref="assets">
        <a-asset-item id="object_01" src="/gltf/df_01.gltf"></a-asset-item>
        <a-asset-item id="object_02" src="/gltf/df_02.gltf"></a-asset-item>
        <a-asset-item id="object_03" src="/gltf/df_03.gltf"></a-asset-item>
        <a-asset-item id="object_04" src="/gltf/df_04.gltf"></a-asset-item>
        <a-asset-item id="object_05" src="/gltf/df_05.gltf"></a-asset-item>
        <audio id="music" src="/mp3/df_music.mp3" preload="auto"></audio>
        <img id="pano" src="/pano/df_pano.jpg" />
      </a-assets>

      <a-sky src="#pano" color="#ffffff" rotation="0 0 0"></a-sky>

      <a-light
        type="point"
        cast-shadow="true"
        intensity="0.2"
        color="#ffffff"
        position="0 8 0"
      ></a-light>

      <a-sound src="#music" autoplay="true" ref="sound"></a-sound>

      <a-camera
        ref="camera"
        look-controls
        wasd-controls="enabled: false"
        reverse-mouse-drag="true"
      >
        <a-light
          type="spot"
          penumbra="0.5"
          angle="40"
          intensity="0.8"
          color="#ffffff"
        ></a-light>
        <a-entity
          cursor="fuse: false; rayOrigin: mouse;"
          raycaster="objects: .clickable"
        ></a-entity>
      </a-camera>

      <a-entity :rotation="`0 ${randomRotation} 0`">
        <a-entity
          :rotation="`0 ${72 * (i - 1) * -1} 0`"
          v-for="i in 5"
          :key="i"
        >
          <a-gltf-model
            class="clickable"
            :src="`#object_0${i}`"
            :router="`path: ${pages[i - 1].route}`"
            position="0 0 -8"
            animation="property: rotation; to: 0 360 0; dur: 90000; easing: linear; loop: true"
          >
          </a-gltf-model>
        </a-entity>
      </a-entity>
    </a-scene>

    <transition name="fade">
      <div
        class="scene__loading window glow--white"
        v-show="$route.path === '/' && !$store.state.scene.loaded"
      >
        Loading...
      </div>
    </transition>
  </div>
</template>

<script>
import TheSettings from "./TheSettings.vue";
import pages from "../content/pages.json";

export default {
  components: { TheSettings },
  data() {
    return {
      pages: pages
    };
  },
  methods: {
    go(route) {
      this.$router.push({
        name: "Window",
        params: {
          window: route
        }
      });
    }
  },
  computed: {
    randomRotation() {
      return Math.floor(Math.random() * 5) * 72;
    }
  },
  watch: {
    "$store.state.settings.muted"() {
      if (this.$store.state.settings.muted) {
        this.$refs.sound.setAttribute("volume", 0);
      } else {
        this.$refs.sound.setAttribute("volume", 1);
      }
    }
  },
  mounted() {
    this.$refs.assets.addEventListener("loaded", () => {
      this.$store.commit("SCENE_STATUS", "loaded");
    });
  }
};
</script>

<style scoped>
.scene {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.scene__title {
  position: absolute;
  top: calc(var(--unit) * 5);
  left: calc(var(--unit) * 5);
  z-index: 10;
}

.scene__settings {
  position: absolute;
  top: calc(var(--unit) * 5);
  right: calc(var(--unit) * 5);
  z-index: 20;
}

.scene__about {
  margin-left: var(--unit);
}

.scene__loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
</style>