<template>
  <div class="settings glow--black">
    <div
      class="settings__setting pointer"
      @click="$store.commit('MUTE_AUDIO', toggle)"
    >
      {{ muted ? "Unmute" : "Mute" }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      muted: state => state.settings.muted
    }),
    toggle() {
      if (this.muted) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.settings {
  display: flex;
}

.settings__setting::after {
  content: "⁕";
  margin: var(--unit);
}

.settings__setting:last-child:after {
  content: "";
  margin: 0;
}
</style>