<template>
  <div class="container">
    <the-scene />
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <the-intro v-if="$store.state.intro.visible" />
  </div>
</template>

<script>
import TheScene from "../components/TheScene.vue";
import TheIntro from "../components/TheIntro.vue";

export default {
  components: { TheScene, TheIntro }
};
</script>
